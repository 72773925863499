import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
		<section id='services'>
			<h5>What I Offer</h5>
			<h2>Services</h2>

			<div className="container services__container">
				<article className="service">
					<div className="service__head">
						<h3>UI/UX Design</h3>
					</div>

					<ul className="service__list">
						<li>
							<BiCheck className='service__list-icon' />
							<p>Using Figma, Canva, etc. to create sample.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
						</li>
					</ul>
				</article>
				{/* END OF UI/UX */}
				<article className="service">
					<div className="service__head">
						<h3>Web Development</h3>
					</div>

					<ul className="service__list">
						<li>
							<BiCheck className='service__list-icon' />
							<p>Using React, Flutter, etc. to build responsive web page.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Using Wordpress to build responsive web page.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Using Squarespace, Wix, etc. to build responsive web page.</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>From simple blog to complicated system.</p>
						</li>
					</ul>
				</article>
				{/* WEB DEVELOPMENT */}
				<article className="service">
					<div className="service__head">
						<h3>Data Related</h3>
					</div>

					<ul className="service__list">
						<li>
							<BiCheck className='service__list-icon' />
							<p>Data collect</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Convert raw data into structured</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Manage data</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Data visualization</p>
						</li>
						<li>
							<BiCheck className='service__list-icon' />
							<p>Analyze data</p>
						</li>
					</ul>
				</article>
				{/* CONTENT CREATION */}
			</div>
		</section>
  )
}

export default Services