import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Experience = () => {
  return (
		<section id='experience'>
			<h5>What Skills I Have</h5>
			<h2>My Experience</h2>

			<div className="container experience__container">
				<div className="experience__frontend">
					<h3>Frontend Development</h3>
					<div className="experience__content">
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>HTML</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>CSS</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>JavaScript</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Flutter/Dart</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>React</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Bootstrap</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
					</div>
				</div>

				<div className="experience__frontend">
					<h3>Backend Development</h3>
					<div className="experience__content">
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Kotlin</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Java</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>SQL</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Python</h4>
								<small className="text-light">Basic</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>NodeJS</h4>
								<small className="text-light">Basic</small>
							</div>
						</article>
					</div>
				</div>

				<div className="experience__frontend">
					<h3>Other Tech</h3>
					<div className="experience__content">
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Git</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Tableau</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>PowerBi</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Office Tools</h4>
								<small className="text-light">Experienced</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Snowflake</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Fivetran</h4>
								<small className="text-light">Intermediate</small>
							</div>
						</article>
					</div>
				</div>

				<div className="experience__frontend">
					<h3>Soft Skills</h3>
					<div className="experience__content">
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Problem Solving</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Analytical Thinking</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Teamwork</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Communication</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Adaptability</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Leadership</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Flexibility</h4>
							</div>
						</article>
						<article className="experience__details">
							<BsPatchCheckFill className='experience__details-icon' />
							<div>
								<h4>Creativity</h4>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Experience