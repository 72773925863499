import React from 'react'
import './portfolio.css'
import JNP from '../../assets/jnp.png'
import IMG2 from '../../assets/portfolio2.jpg'
import SURA from '../../assets/portfolio-sura.png'
import CANAAN from '../../assets/portfolio-canaan.png'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
	{
		id: 1,
		image: JNP,
		title: "My Family's Blog",
		built: "Built with Wordpress",
		github: 'https://github.com',
		demo: 'https://jnpnote.com'
	},
	{
		id: 2,
		image: IMG2,
		title: 'Portfolio Website Practice',
		built: "Built with React",
		github: 'https://github.com/jisoooh0202/react-portfolio-website-tutorial',
		demo: 'https://dribbble.com/shots/16580766-Orion-UI-kit-Charts-templates-infographics-in-Figma'
	},
	{
		id: 3,
		image: SURA,
		title: 'Sura Korean Restaurant Website',
		built: "Built with Squarespace",
		github: 'https://github.com',
		demo: 'https://www.sura2012.com/'
	},
	{
		id: 4,
		image: CANAAN,
		title: 'Canaan Farming School',
		built: "Built with Wordpress",
		github: 'https://github.com',
		demo: 'https://www.canaanin.or.kr/'
	},
	{
		id: 5,
		image: IMG5,
		title: 'Charts templates & infographics in Figma',
		built: "Built with Wordpress",
		github: 'https://github.com',
		demo: 'https://dribbble.com/shots/16541289-Orion-UI-kit-Charts-templates-infographics-in-Figma'
	},
	{
		id: 6,
		image: IMG6,
		title: 'Charts templates & infographics in Figma',
		built: "Built with Wordpress",
		github: 'https://github.com',
		demo: 'https://dribbble.com/shots/15887665-Orion-UI-kit-Charts-templates-infographics-in-Figma'
	},
]

const Portfolio = () => {
  return (
		<section id='portfolio'>
			<h5>My Recent Work</h5>
			<h2>Portfolio</h2>

			<div className="container portfolio__container">
				{
					data.map(({id, image, title, built, github, demo}) => {
						return (
							<article key={id} className="portfolio__item">
								<div className="portfolio__item-image">
									<img src={image} alt={title} />
								</div>
								<h3>{title}</h3>
								<p>{built}</p>
								<div className="portfolio__item-cta">
									<a href={github} className="btn" target='_blank'>Github</a>
									<a href={demo} className="btn btn-primary" target='_blank'>Live Demo</a>
								</div>
							</article>
						)
					})
				}
			</div>
		</section>
  )
}

export default Portfolio