import React from 'react'

const HeaderRight = () => {
  return (
		<div className='scroll__down'>
			<a href="#contact" target="_self" className='contact'>Contact Me</a>
		</div>
  )
}

export default HeaderRight