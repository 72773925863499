import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
	const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_06ci38j', 'template_rk3klut', form.current, 'BLf05zLQ_KIAncOac')

		e.target.reset()
  };

  return (
		<section id='contact'>
			<h5>Get In Touch</h5>
			<h2>Contact Me</h2>

			<div className="container contact__container">
				<div className="contact__options">
					<article className="contact__option">
						<MdOutlineEmail className='contact__option-icon'/>
						<h4>Email</h4>
						<h5 className='text-light'>jisoo@jnpnote.com</h5>
						<a href="mailto:jisoo@jnpnote.com" target="_blank">Send a message</a>
					</article>

					<article className="contact__option">
						<RiMessengerLine className='contact__option-icon'/>
						<h4>Messenger</h4>
						<h5 className='text-light'>Jisoo Oh</h5>
						<a href="https://m.me/jisoooh0202" target="_blank">Send a message</a>
					</article>

					<article className="contact__option">
						<BsWhatsapp className='contact__option-icon'/>
						<h4>WhatsApp</h4>
						<h5 className='text-light'>+66 61 319 2582</h5>
						<a href="https://wa.me/66613192582" target="_blank">Send a message</a>
					</article>
				</div>
				{/* END OF CONTACT OPTIONS */}
				<form ref={form} onSubmit={sendEmail}>
					<input type="text" name='name' placeholder='Your Full Name' required />
					<input type="email" name='email' placeholder='Your Email' required />
					<input type="text" name='subject' placeholder='Subject' required />
					<textarea name="message" rows="10" placeholder='Your Message' required></textarea>
					<button type='submit' className='btn btn-primary'>Send Message</button>
				</form>
			</div>
		</section>
  )
}

export default Contact