import React from 'react'
import './about.css'
import AboutMe from '../../assets/about_me.png'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'


const About = () => {
  return (
		<section id='about'>
			<h5>Get To Know</h5>
			<h2>About Me</h2>

			<div className="container about__container">
				<div className="about__me">
					<div className="about__me-image">
						<img src={AboutMe} alt="About Image" />
					</div>
				</div>

				<div className="about__content">
					<div className="about__cards">
						<article className="about__card">
							<FaAward className='about__icon'/>
							<h5>Experience</h5>
							<small>3+ Years Working</small>
						</article>

						<article className="about__card">
							<FiUsers className='about__icon'/>
							<h5>Clients</h5>
							<small>5+ Worldwide</small>
						</article>

						<article className="about__card">
							<VscFolderLibrary className='about__icon'/>
							<h5>Projects</h5>
							<small>3+ Completed</small>
						</article>
					</div>

					<p>
						I am a highly motivated and driven entrepreneur who has utilized my engineering background and natural problem-solving abilities to produce results in a wide range of industries. I am a self-taught web designer and programmer, looking to grow as an engineer and businessman and leverage my expertise and experience in a new technology venture.
					</p>

					<a href="#contact" className='btn btn-primary'>Let's Talk</a>
				</div>
			</div>
		</section>
  )
}

export default About